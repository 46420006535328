@font-face {
	font-family: "Satosi";
	src: url("./fonts/Satoshi-Regular.ttf");
}
@font-face {
	font-family: "Satosi-bold";
	src: url("./fonts/Satoshi-Bold.ttf");
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Satosi-bold";
}

p,
span,
a {
	font-family: "Satosi";
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.select_box_main fieldset {
	border: none;
}
.select_box_main,
.select_box_main:focus {
	border: 1px solid #fff;
}
.select_box_main div[role="button"] {
	padding: 6.5px 14px;
}

.text-muted {
	color: #b0b0b0 !important;
}

.checkbox_color svg {
	fill: #fff;
}
.dynamic_single_model {
	overflow-y: scroll;
}

.close {
	position: absolute;
	right: 30px;
}
.media_game .one {
	display: flex;
}
.media_game .one .MuiAvatar-root,
.media_game .one span {
	margin-top: auto;
	margin-bottom: auto;
}

.media_game {
	flex-wrap: wrap;
}

.image_url {
	min-height: 250px;
}
.image_url img {
	max-width: 100%;
}

.description p {
	margin-bottom: 5px;
}
.news_background_image {
	background-repeat: no-repeat;
	background-position: center;
	min-height: 350px;
	background-size: cover;
}

.worst_model .light-bg {
	top: 100% !important;
	border: 2px solid #fff !important;
}

.worst_model .dark_color input {
	color: #fff;
}

@media screen and (max-width: 450px) {
	.game_score > div {
		width: 100%;
	}
	.game_score > div .thumbnail-wrapper {
		height: 100px !important;
	}
}
@media screen and (max-width: 991px) {
	/* div[role="presentation"] .light-bg {
		top: 100% !important;
	} */
}
