.footer-upper-menu a {
	color: #fff;
	text-decoration: none;
}

@media screen and (max-width: 390px) {
	.footer-upper-menu {
		display: flex;
		flex-wrap: wrap;
	}
}
