.tollbar_main {
	flex-wrap: wrap;
	margin-top: 10px;
	padding-bottom: 18px;
}
.tollbar_main .left {
	width: 20%;
}
.tollbar_main .middle {
	display: flex;
	width: 50%;
}
.tollbar_main .right {
	display: flex;
	width: 30%;
	justify-content: right;
}
.tollbar_main .middle .search_box {
	width: 100%;
}
.search_box,
.search_form,
.search_btn {
	margin-top: auto;
	margin-bottom: auto;
}

.tollbar_main .right button {
	margin-top: -3px;
}
.search_box .custom_search {
	position: relative;
}
.search_result {
	position: absolute;
	top: 45px;
	background: #000;
	z-index: 10;
	border-radius: 8px;
	width: 100%;
	padding-top: 10px;
	padding-bottom: 10px;
	height: 320px;
	overflow: auto;
}
.result_item {
	color: #000 !important;
	cursor: pointer;
	padding: 5px 10px;
	display: flex;
}
.result_item:hover {
	border-radius: 8px;
	filter: brightness(120%);
	background: #3c3a3a;
}
.result_item .result_item_left {
	width: 100px;
	margin-right: 10px;
}
.result_item .result_item_left img {
	max-width: 100%;
}
.result_item .result_item_right p {
	margin-bottom: 0px;
	color: #fff;
}
.result_item .result_item_right p.rating {
	font-size: 14px;
}
.search_result_link {
	text-decoration: none;
}

#primary-search-account-menu {
	overflow: unset;
}

.search_result::-webkit-scrollbar {
	width: 5px;
	border-radius: 10px;
}

.search_result::-webkit-scrollbar-track {
	box-shadow: inset 10px 10px 10px #000;
}

.search_result::-webkit-scrollbar-thumb {
	border: 4px solid transparent;
	border-radius: 8px;
	background-color: #fff;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-background-clip: text;
	-webkit-text-fill-color: #ffffff;
	transition: background-color 5000s ease-in-out 0s;
	box-shadow: inset 0 0 20px 20px #23232329;
}
@media screen and (max-width: 1090px) {
	.tollbar_main .left {
		width: 50%;
		order: 1;
	}
	.tollbar_main .right {
		width: 50%;
		order: 1;
	}
	.tollbar_main .middle {
		width: 100%;
		order: 3;
		margin-top: 20px;
	}
	.tollbar_main .middle .custom_search {
		margin-left: 0px;
	}
}

@media screen and (max-width: 600px) {
	.middle {
		flex-wrap: wrap;
	}
	.search_form .select_box_main div[role="button"] {
		font-size: 14px !important;
	}
	.select_box_main div[role="button"] {
		padding: 5.3px 14px;
	}
	.custom_search {
		margin-bottom: 10px;
	}
	.search_form .search_btn button {
		font-size: 14px !important;
		padding-left: 6px;
		padding-right: 6px;
	}
}

@media screen and (max-width: 440px) {
	.tollbar_main .left {
		width: 70%;
	}
	.tollbar_main .right {
		width: 30%;
	}
}
